import vertex from "./Ortho.glslv";
import fragment from "./Final.glslf";

let Final = {
  vertexShader: vertex,
  fragmentShader: fragment,
  uniforms: {
    u_color: { type: "t", value: null },
    resolution: {
      value: new THREE.Vector2(window.innerWidth, window.innerHeight),
    },
    toneMappingExposure: { type: "f", value: 1.0 },
  },
  defines: {
    TONEMAP: true,
    SRGB: false,
    NO_FINAL_ALPHA: false,
    MONOCHROMATIC_FINAL: false,
    TONEMAP_SIMPLE: false,
    TONEMAP_REINHARD: false,
    TONEMAP_REINHARD_LUMA: false,
    TONEMAP_REINHARD_WHITE: false,
    TONEMAP_FILMIC: false,
    TONEMAP_FILMIC: false,
    TONEMAP_PHOTOGRAPHIC: false,
    TONEMAP_UNCHARTED: true,
  },
};

export default Final;
