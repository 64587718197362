import vertex from "./Ortho.glslv"
import fragment from "./ToneMapping.glslf"

let ToneMapping = {
    vertexShader: vertex,
    fragmentShader: fragment,
    uniforms: {
        u_color: { type: "t", value: null },
        toneMappingExposure: { type: "f", value: null}
    },
    defines: {
        TONEMAP: true,
        TONEMAP_SIMPLE:false,
        TONEMAP_REINHARD: false,
        TONEMAP_REINHARD_LUMA: false,
        TONEMAP_REINHARD_WHITE: false,
        TONEMAP_FILMIC: false,
        TONEMAP_FILMIC: false,
        TONEMAP_PHOTOGRAPHIC: false,
        TONEMAP_UNCHARTED: false
    }
}

export default ToneMapping;
