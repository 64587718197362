import fragment from "./SSAOBlur.glslf";
import vertex from "./Ortho.glslv";

const SSAOBlurShader = {
  uniforms: {
    u_color: {
      value: null,
    },
    resolution: {
      value: new THREE.Vector2(),
    },
  },
  vertexShader: vertex,
  fragmentShader: fragment,
};

export default SSAOBlurShader;
