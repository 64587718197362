import fragment from "./applyMask.glslf";
import vertex from "./Ortho.glslv";

const applyMask = {
  uniforms: {
    gtaocolor: { value: new THREE.Color() },
    u_color: { value: null },
    u_mask: { value: null },
  },
  fragmentShader: fragment,
  vertexShader: vertex,
};

export default applyMask;
