import { iMaterial } from "./materials/imaterial";
import physicalMaterial from "./materials/physicalMaterial";
import GBufferMaterial from "./materials/GBuffer";
import {
  composerRender,
  getZPassTexture,
  perFrameShadowMaterial,
  setUniforms,
  updatePostprocessesState,
  updateShadowCamera,
  updateShadowMaterial,
} from "./composer";
import { buildGUI } from "./gui";
// import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
// import * as THREE from "three";
import getState from "./state";

const state = getState();
let sim, crossGeometry, scene, camera;
let sim_tex;
let center = new THREE.Vector3(
  42.057 - 0.0915,
  14.2313 + 0.0325,
  105.8319 - 0.977,
);

let centerRotation = new THREE.Vector3(42.057 - 0.0915, 14.2313 + 0.0325, 20);
// let cameraStartPosition = new THREE.Vector3(42.057, 14.2313, 105.8319);
let cameraStartPosition = new THREE.Vector3(42.057, 44.2313, 105.8319);
let cameraTargetPosition = cameraStartPosition.clone();
let gl, renderer;
let areaLightRect;
// let particleDensity = 0.1;
let gridCellDensity = 0.1;

const referenceWidth = 1728;
const referenceHeight = 909;
const referenceAspect = referenceWidth / referenceHeight;
const referenceGridWidth = 165;
const referenceGridDepth = 75;

const referenceCamera0PositionX = 83.0;
const referenceCamera1PositionX = 79.0;

const fakeRT = new THREE.WebGLRenderTarget();
const pointer = new THREE.Vector2();
const lastPoint = new THREE.Vector3();
const mouseVelocity = new THREE.Vector3();
const rayDirection = new THREE.Vector3();

const raycaster = new THREE.Raycaster();
const iPlane = new THREE.Mesh(
  new THREE.PlaneGeometry(10000, 10000),
  new THREE.MeshBasicMaterial({ color: "black" }),
);

let pressed = false;

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

const regimeDict = {
  still: 0,
  waves: 1,
  vortex: 2,
};

const materialDict = {
  Cross: null,
  Sphere: null,
  Box: null,
  Capsule: null,
};

const materials = [];

export function setPixelRatio() {
  renderer.setPixelRatio(state.pixelRatio);
}

export function updateMaterials() {
  state.meshSettings.forEach((settings) => {
    const material = materialDict[settings.name];
    if (!material) return;
    material.uniforms.roughness.value = settings.material.roughness;
    material.uniforms.metalness.value = settings.material.metalness;
    material.uniforms.color.value = new THREE.Color(settings.material.color);

    material.defines["ROTATE"] = state.meshRotation;
    material.needsUpdate = true;
    updateShadowMaterial();
  });
}

export function updateCameraFov() {
  camera.fov = state.cameras[state.chosenCamera].fov;
  camera.updateProjectionMatrix();
}

export function updateLights() {
  areaLightRect.position.copy(state.areaLight.position);
  areaLightRect.scale.set(state.areaLight.width, state.areaLight.height, 1.0);
  areaLightRect.rotation.set(
    state.areaLight.rotation.x,
    state.areaLight.rotation.y,
    state.areaLight.rotation.z,
  );
  areaLightRect.updateMatrix();
  areaLightRect.updateMatrixWorld();
  areaLightRect.visible = state.areaLight.rectVisible;

  const lights = {
    ambient: {
      color: state.ambientLightColor,
      intensity: state.ambientLightIntensity,
    },
    rect: {
      position: areaLightRect.position,
      color: new THREE.Color(state.areaLight.color),
      intensity: state.areaLight.intensity, //18.0,
      width: state.areaLight.width,
      height: state.areaLight.height,
      modelMatrix: areaLightRect.matrixWorld,
      useShadow: 0,
    },
  };

  updateShadowCamera(areaLightRect);
  setUniforms(camera, lights, sim_tex);
}

function readLocalStorage() {
  const gridStorageData = localStorage.getItem("grid");
  if (gridStorageData) {
    const data = JSON.parse(gridStorageData);
    state.gridWidth = data.gridWidth;
    state.gridHeight = data.gridHeight;
    state.gridDepth = data.gridDepth;
    state.density = data.density;
  }

  const meshStorageData = localStorage.getItem("meshData");
  if (meshStorageData) {
    const data = JSON.parse(meshStorageData);
    state.useOneMesh = data.useOneMesh;
    state.meshToUse = data.meshToUse;
    state.meshSettings.forEach((s) => {
      s.scale = data.scales[s.name];
    });
  }

  const screenAdaptation = localStorage.getItem("screenAdaptation");
  if (screenAdaptation) {
    const data = JSON.parse(screenAdaptation);
    state.screenAdaptation = data.on;
  }

  const stopRender = localStorage.getItem("stopRenderFPS");
  if (stopRender) {
    const data = JSON.parse(stopRender);
    state.stopRenderFPS = data.fps;
  }
}

export function adaptToScreen() {
  if (!state.screenAdaptation) return;

  state.cameras[0].cameraPosition.x = referenceCamera0PositionX;
  state.cameras[0].cameraPosition.x *=
    window.innerWidth / window.innerHeight / referenceAspect;

  state.cameras[1].cameraPosition.x = referenceCamera1PositionX;
  state.cameras[1].cameraPosition.x *=
    window.innerWidth / window.innerHeight / referenceAspect;

  // state.cameras[0].cameraPosition.x *=
  //   window.innerWidth / window.innerHeight / referenceAspect;
  //
  // state.cameras[1].cameraPosition.x *=
  //   window.innerWidth / window.innerHeight / referenceAspect;

  // state.cameras[1].cameraPosition.z *=
  //   (window.innerHeight / window.innerWidth) * referenceAspect;

  state.gridWidth =
    (referenceGridWidth * window.innerWidth) /
    window.innerHeight /
    referenceAspect;
  state.gridWidth = Math.floor(state.gridWidth);
  state.gridDepth = referenceGridDepth;

  const previousFactor = state.interaction.factor;
  state.interaction.factor *=
    window.innerWidth / window.innerHeight / referenceAspect;
  state.interaction.factor = Math.min(previousFactor, state.interaction.factor);
}

function init() {
  readLocalStorage();
  adaptToScreen();
  buildGUI(state);
  renderer = new THREE.WebGLRenderer({
    preserveDrawingBuffer: true,
    antialias: true,
    precision: "highp",
  });

  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0x0, 0);

  let container = document.getElementsByClassName("container")[0];
  container.appendChild(renderer.domElement);
  let context = document.getElementsByTagName("canvas")[0];
  scene = new THREE.Scene();
  // scene.background = new THREE.Color("white");
  camera = new THREE.PerspectiveCamera(
    state.cameras[state.chosenCamera].fov,
    window.innerWidth / window.innerHeight,
    0.1,
    1000,
  );

  const gMaterial = GBufferMaterial();
  areaLightRect = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), gMaterial);

  gMaterial.uniforms.roughness.value = 0.5;
  gMaterial.uniforms.metalness.value = 0.2;
  gMaterial.uniforms.color.value = new THREE.Color("#ffffff");
  gMaterial.defines["PARTICLES"] = false;

  areaLightRect.position.copy(state.areaLight.position);
  areaLightRect.scale.set(state.areaLight.width, state.areaLight.height, 1.0);
  areaLightRect.rotation.set(
    state.areaLight.rotation.x,
    state.areaLight.rotation.y,
    state.areaLight.rotation.z,
  );
  scene.add(areaLightRect);
  areaLightRect.visible = state.areaLight.rectVisible;

  const cameraData = state.cameras[state.chosenCamera];

  camera.position.copy(cameraData.cameraPosition);
  // camera.rotation.copy(cameraData.cameraRotation);
  camera.rotation.set(
    cameraData.cameraRotation.x,
    cameraData.cameraRotation.y,
    cameraData.cameraRotation.z,
    "XYZ",
  );
  scene.add(camera);

  let wgl = new WrappedGL(context, {
    antialias: false,
    depth: false,
  });

  gl = wgl.gl;
  new Promise((res, rej) => {
    new OBJLoader().load("./meshes/cross.obj", function (object) {
      crossGeometry = object.children[0].geometry; //OBJ

      let N = crossGeometry.attributes.position.count;
      let index = [];
      for (let i = 0; i < N; i++) {
        index.push(i);
      }
      crossGeometry.setIndex(index);

      res(0);
    });
  }).then(() => {
    sim = new Simulator(wgl, () => {
      generate(sim, scene);

      const lights = {
        ambient: {
          color: state.ambientLightColor,
          intensity: state.ambientLightIntensity,
        },
        rect: {
          position: areaLightRect.position,
          color: new THREE.Color(state.areaLight.color),
          intensity: state.areaLight.intensity, //18.0,
          width: state.areaLight.width,
          height: state.areaLight.height,
          modelMatrix: areaLightRect.matrixWorld,
          useShadow: 0,
        },
      };

      updateShadowCamera(areaLightRect);
      setUniforms(camera, lights, sim_tex);
      animate();
    });
  });
}

function generate() {
  var gridCells =
    state.gridWidth * state.gridHeight * state.gridDepth * gridCellDensity;
  const particleDensity = state.density;

  //assuming x:y:z ratio of 2:1:1
  var gridResolutionX = Math.ceil(Math.pow(gridCells / 2, 1.0 / 3.0));
  var gridResolutionZ = gridResolutionX * 1;
  var gridResolutionY = gridResolutionX * 2;

  let particlesWidth = 512;
  var desiredParticleCount =
    gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity; //theoretical number of particles
  var particlesHeight = Math.ceil(desiredParticleCount / particlesWidth); //then we calculate the particlesHeight that produces the closest particle count

  var particlePositions = [];

  let flatParticlePositions = new Float32Array(
    3 * gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity,
  );
  let flatParticleUV = new Float32Array(
    2 * gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity,
  );
  for (var i = 0; i < gridResolutionX; i++) {
    for (var j = 0; j < gridResolutionY; j++) {
      for (var k = 0; k < gridResolutionZ; k++) {
        let pos = [i + Math.random(), j + Math.random(), k + Math.random()];
        flatParticlePositions[3 * particlePositions.length + 0] = pos[0];
        flatParticlePositions[3 * particlePositions.length + 1] = pos[1];
        flatParticlePositions[3 * particlePositions.length + 2] = pos[2];

        // particlePositions.push([pos[0], pos[1], pos[2]]);
        particlePositions.push([pos[0], pos[1], pos[2]]);
      }
    }
  }

  for (var y = 0; y < particlesHeight; ++y) {
    for (var x = 0; x < particlesWidth; ++x) {
      flatParticleUV[(y * particlesWidth + x) * 2] = (x + 0.5) / particlesWidth;
      flatParticleUV[(y * particlesWidth + x) * 2 + 1] =
        (y + 0.5) / particlesHeight;
    }
  }
  var gridSize = [state.gridWidth, state.gridHeight, state.gridDepth];
  var gridResolution = [gridResolutionX, gridResolutionY, gridResolutionZ];

  let count =
    Math.ceil(
      gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity,
    ) - 1;
  let range = Array.from(new Array(count), (x, i) => i);
  let randomInds = shuffle(range);

  let indsSphere = randomInds.slice(0, count / 4);
  let indsCross = randomInds.slice(count / 4, count / 2);
  let indsCube = randomInds.slice(count / 2, (3 * count) / 4);
  let indsBrand = randomInds.slice((3 * count) / 4, count);

  let sphereUVs = [];
  let crossUVs = [];
  let cubeUVs = [];
  let brandUVs = [];

  indsSphere.forEach((x) => {
    sphereUVs.push(flatParticleUV[2 * x]);
    sphereUVs.push(flatParticleUV[2 * x + 1]);
  });

  indsCross.forEach((x) => {
    crossUVs.push(flatParticleUV[2 * x]);
    crossUVs.push(flatParticleUV[2 * x + 1]);
  });

  indsCube.forEach((x) => {
    cubeUVs.push(flatParticleUV[2 * x]);
    cubeUVs.push(flatParticleUV[2 * x + 1]);
  });

  indsBrand.forEach((x) => {
    brandUVs.push(flatParticleUV[2 * x]);
    brandUVs.push(flatParticleUV[2 * x + 1]);
  });

  sim.reset(
    particlesWidth,
    particlesHeight,
    particlePositions,
    gridSize,
    gridResolution,
    particleDensity,
  );

  //three texture to copy simulation framebuffer
  sim_tex = new THREE.FramebufferTexture(
    particlesWidth,
    particlesHeight,
    "RGBA16F",
  );
  sim_tex.needsUpdate = true;

  let meshUVGeometryMap;
  const gs = {
    Sphere: new THREE.SphereGeometry(0.7, 6, 6),
    Cross: crossGeometry,
    Box: new THREE.BoxGeometry(1, 1, 1),
    Capsule: new THREE.CapsuleGeometry(0.5, 0.5, 2, 4),
  };

  if (state.useOneMesh) {
    let index = 0;
    state.meshSettings.forEach((s, i) => {
      if (state.meshToUse === s.name) index = i;
    });
    meshUVGeometryMap = [[flatParticleUV, gs[state.meshToUse], index]];
  } else {
    meshUVGeometryMap = [
      [sphereUVs, gs["Sphere"], 0],
      [crossUVs, gs["Cross"], 1],
      // [crossUVs, new THREE.BoxGeometry(1, 1, 1)],
      [cubeUVs, gs["Box"], 2],
      [brandUVs, gs["Capsule"], 3],
    ];
  }

  meshUVGeometryMap.forEach(([uvs, instance, index]) => {
    // let materialS = iMaterial();
    const settings = state.meshSettings[index];
    // let materialS = physicalMaterial();
    let materialS = GBufferMaterial();

    materialDict[settings.name] = materialS;
    materials.push(materialS);

    materialS.uniforms.u_positionsTexture.value = sim_tex;
    materialS.uniforms.roughness.value = settings.material.roughness;
    materialS.uniforms.metalness.value = settings.material.metalness;
    materialS.uniforms.color.value = new THREE.Color(settings.material.color);
    materialS.defines["PARTICLES"] = true;

    var geometry = new THREE.InstancedBufferGeometry();
    geometry.index = instance.index;
    geometry.instanceCount = uvs.length / 2;

    geometry.setAttribute("position", instance.attributes.position);
    geometry.setAttribute("normal", instance.attributes.normal);
    geometry.setAttribute(
      "uv",
      new THREE.InstancedBufferAttribute(new Float32Array(uvs), 2),
    );

    const toLook = new THREE.Vector3();
    toLook.copy(state.cameras[state.cameraToLook].cameraPosition);
    geometry.lookAt(toLook);
    geometry.scale(settings.scale, settings.scale, settings.scale);

    let mesh = new THREE.Mesh(geometry, materialS);
    mesh.frustumCulled = false;

    scene.add(mesh);
  });
}

function firstCheckProcedure(startFrame, startTime, frameCounter) {
  let timeOverCounter =
    (performance.now() - startTime) / (frameCounter - startFrame);
  timeOverCounter /= 1000;
  let startFPS = 1 / timeOverCounter;

  let final = false;
  if (startFPS < state.stopRenderFPS) {
    console.log("First check FPS is ", startFPS, ". Set static.");
    final = false;
  } else {
    final = true;
    console.log(
      "First check FPS is ",
      startFPS,
      ". Good Enough with high settings!",
    );
  }
  return final;
}

let timePassed = 0.0;
let startTime;
let frameCounter = 0;
let renderingChosen = false;
let check = false;
let toRender = true;

export function stopRunRender() {
  toRender = false;
}

export function continueRender() {
  toRender = true;
  animate();
}

function animate() {
  // camera.position.x += 0.01 * (cameraTargetPosition.x - camera.position.x);
  // camera.position.y += 0.01 * (cameraTargetPosition.y - camera.position.y);
  // camera.position.z += 0.01 * (cameraTargetPosition.z - camera.position.z);
  // camera.lookAt(centerRotation);

  if (!toRender) return;

  frameCounter++;
  let startFrame1 = 20;
  let finishFrame1 = 60;

  if (
    frameCounter > startFrame1 &&
    !renderingChosen &&
    frameCounter == finishFrame1
  ) {
    check = firstCheckProcedure(startFrame1, startTime, frameCounter);
    renderingChosen = true;
  }

  if (frameCounter == startFrame1) {
    startTime = performance.now();
  }

  if (renderingChosen && !check) {
    return;
  }

  const cameraData = state.cameras[state.chosenCamera];
  camera.position.x += 0.01 * (cameraData.cameraPosition.x - camera.position.x);
  camera.position.y += 0.01 * (cameraData.cameraPosition.y - camera.position.y);
  camera.position.z += 0.01 * (cameraData.cameraPosition.z - camera.position.z);
  camera.rotation.x += 0.01 * (cameraData.cameraRotation.x - camera.rotation.x);
  camera.rotation.y += 0.01 * (cameraData.cameraRotation.y - camera.rotation.y);
  camera.rotation.z += 0.01 * (cameraData.cameraRotation.z - camera.rotation.z);

  // camera.position.copy(cameraData.cameraPosition);
  // camera.rotation.copy(cameraData.cameraRotation);

  // iPlane.quaternion.copy(camera.quaternion);
  iPlane.lookAt(camera.position);

  raycaster.setFromCamera(pointer, camera);

  // calculate objects intersecting the picking ray
  const intersects = raycaster.intersectObjects([iPlane]);
  if (intersects.length > 0) {
    const point = intersects[0].point;

    mouseVelocity.copy(point);
    mouseVelocity.sub(lastPoint);
    lastPoint.copy(point);

    rayDirection.copy(point);
    rayDirection.sub(camera.position);
    rayDirection.normalize();

    // console.log(mouseVelocity.length());
  }

  if (mouseVelocity.length() > 10) {
    mouseVelocity.set(0, 0, 0);
  }

  renderer.setClearColor(0x0, 0);
  renderer.clear();

  gl.disable(gl.DEPTH_TEST);
  gl.disable(gl.CULL_FACE);

  const delta = 0.01 * state.speed;

  timePassed += delta; //0.05;

  sim.flipness = state.fluidity;
  sim.simulate(
    delta,
    timePassed,
    0.02,
    0.15,
    mouseVelocity,
    camera.position,
    rayDirection,
    {
      regime: regimeDict[state.regime],
      interactionStrength: state.interaction.strength,
      interactionRadius: state.interaction.radius,
      wavesStrength: state.wavesStrength,
      vortexBorder: state.vortexBorder,
      vortexStrength: state.vortexStrength,
    },
  );
  renderer.initTexture(sim_tex);
  renderer.copyFramebufferToTexture(new THREE.Vector2(0, 0), sim_tex);
  renderer.resetState();

  if (state.meshRotation) {
    for (let i = 0; i < materials.length; i++)
      materials[i].uniforms.t.value = timePassed;

    perFrameShadowMaterial(timePassed);
  }

  // renderer.render(scene, camera);
  composerRender(renderer, scene, camera);
  requestAnimationFrame(animate);
}

function rotate(d) {
  const r = new THREE.Vector3();
  const q = new THREE.Quaternion().setFromAxisAngle(
    new THREE.Vector3(0, 1, 0),
    d,
  );

  r.copy(cameraStartPosition);
  r.sub(centerRotation);
  r.applyQuaternion(q);
  r.add(centerRotation);

  cameraTargetPosition.copy(r);
}

window.addEventListener("mousedown", () => {
  pressed = true;
});

window.addEventListener("mouseup", () => {
  pressed = false;
});

window.addEventListener("mousemove", (e) => {
  const x = 2 * (e.clientX / window.innerWidth) - 1;

  // if (pressed) {
  //   rotate(-x * ((0.8 * Math.PI) / 2));
  // }
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

  // console.log(x);
});

window.addEventListener("touchmove", (event) => {
  pointer.x = event.touches[0].clientX - window.innerWidth / 2;
  pointer.y = -(event.touches[0].clientY - window.innerHeight / 2);

  pointer.x /= window.innerWidth * 0.5;
  pointer.y /= window.innerHeight * 0.5;
});

window.addEventListener("resize", () => {
  const state = getState();
  state.width = window.innerWidth;
  state.height = window.innerHeight;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
  updatePostprocessesState();
});

init();
