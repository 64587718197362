import { Pane } from "tweakpane";
import { updatePostprocessesState } from "./composer";
import {
  continueRender,
  setPixelRatio,
  stopRunRender,
  updateCameraFov,
  updateLights,
  updateMaterials,
  adaptToScreen,
} from ".";

const input = document.createElement("input");

function putGridDataToLocalStorage(state) {
  const serialized = JSON.stringify({
    gridWidth: state.gridWidth,
    gridHeight: state.gridHeight,
    gridDepth: state.gridDepth,
    density: state.density,
  });
  const localStorage = window.localStorage;
  localStorage.setItem("grid", serialized);
}

function putMeshesDataToLocalStorage(state) {
  const object = {
    useOneMesh: state.useOneMesh,
    meshToUse: state.meshToUse,
    scales: {},
  };

  state.meshSettings.forEach((s) => {
    object.scales[s.name] = s.scale;
  });
  const serialized = JSON.stringify(object);

  const localStorage = window.localStorage;
  localStorage.setItem("meshData", serialized);
}

function readState(newState, state) {
  const badNames = [];
  const recursiveStateUpdate = (inState, toState) => {
    if (Array.isArray(toState)) {
      for (let i = 0; i < toState.length; i++) {
        if (typeof toState[i] === "object")
          recursiveStateUpdate(inState[i], toState[i]);
        else toState[i] = inState[i];
      }
    } else {
      Object.keys(toState).forEach((key) => {
        if (badNames.indexOf(key) !== -1) return;
        if (
          typeof inState[key] !== "undefined" &&
          typeof inState[key] !== "object"
        ) {
          toState[key] = inState[key];
        } else if (inState[key] && typeof inState[key] === "object") {
          recursiveStateUpdate(inState[key], toState[key]);
        }
      });
    }
  };

  recursiveStateUpdate(newState, state);
}

function updateEverything(state) {
  adaptToScreen();
  updateMaterials();
  updateLights();
  updateCameraFov();
  putGridDataToLocalStorage(state);
  putMeshesDataToLocalStorage(state);
  updatePostprocessesState();
}

export function buildGUI(state) {
  const pane = new Pane();

  const render = pane.addFolder({ title: "Rendering", expanded: false });
  const post = render.addFolder({ title: "Postprocess", expanded: false });
  const gtao = post.addFolder({ title: "GTAO", expanded: false });
  gtao
    .addBinding(state, "GTAO", {
      options: {
        off: "off",
        "non-blured": "non-blured",
        blured: "blured",
      },
    })
    .on("change", () => {
      updatePostprocessesState();
    });

  gtao.addBinding(state, "scaling", { min: 0, max: 40 }).on("change", () => {
    updatePostprocessesState();
  });

  gtao
    .addBinding(state, "sliceCount", { min: 0, max: 32, step: 1 })
    .on("change", () => {
      updatePostprocessesState();
    });
  gtao
    .addBinding(state, "directionalSamples", { min: 0, max: 32, step: 1 })
    .on("change", () => {
      updatePostprocessesState();
    });

  const bloom = post.addFolder({ title: "Bloom", expanded: false });
  bloom
    .addBinding(state, "Bloom", { options: { on: "on", off: "off" } })
    .on("change", () => {
      updatePostprocessesState();
    });
  bloom
    .addBinding(state, "filterRadius", { min: 0.0, max: 0.2 })
    .on("change", () => {
      updatePostprocessesState();
    });
  bloom
    .addBinding(state, "bloomStrength", { min: 0.0, max: 10 })
    .on("change", () => {
      updatePostprocessesState();
    });

  // render
  //   .addBinding(state, "geometryRenderMSAASamples", {
  //     min: 0,
  //     max: 32,
  //     step: 1,
  //   })
  //   .on("change", () => {
  //     updatePostprocessesState();
  //   });
  render
    .addBinding(state, "pixelRatio", { min: 0, max: 3 })
    .on("change", () => {
      setPixelRatio();
      updatePostprocessesState();
    });

  render.addBinding(state, "backgroundColor").on("change", () => {
    updatePostprocessesState();
  });

  render
    .addBinding(state, "ToneMappingExposure", { min: 0, max: 5 })
    .on("change", () => {
      updatePostprocessesState();
    });

  render
    .addBinding(state, "ToneMapping", {
      options: {
        NO_TONEMAP: "NONE",
        TONEMAP_SIMPLE: "TONEMAP_SIMPLE",
        TONEMAP_REINHARD: "TONEMAP_REINGHARD",
        TONEMAP_REINHARD_LUMA: "TONEMAP_REINHARD_LUMA",
        TONEMAP_REINHARD_WHITE: "TONEMAP_REINHARD_WHITE",
        TONEMAP_FILMIC: "TONEMAP_FILMIC",
        TONEMAP_PHOTOGRAPHIC: "TONEMAP_PHOTOGRAPHIC",
        TONEMAP_UNCHARTED: "TONEMAP_UNCHARTED",
      },
    })
    .on("change", () => {
      updatePostprocessesState();
    });

  const sim = pane.addFolder({ title: "Simulation", expanded: false });

  sim.addBinding(state, "regime", {
    options: {
      still: "still",
      waves: "waves",
      vortex: "vortex",
    },
  });
  sim.addBinding(state, "fluidity", { min: 0, max: 1 });
  sim.addBinding(state, "speed", { min: 0, max: 10 });
  sim.addBinding(state, "wavesStrength", { min: 0, max: 50 });
  sim.addBinding(state, "vortexBorder", { min: 0, max: 50 });
  sim.addBinding(state, "vortexStrength", { min: 0, max: 50 });

  const interaction = pane.addFolder({ title: "Interaction", expanded: false });

  interaction.addBinding(state.interaction, "color").on("change", () => {
    updateLights();
  });
  interaction.addBinding(state.interaction, "strength", { min: 0, max: 50 });
  interaction.addBinding(state.interaction, "radius", { min: 0, max: 50 });
  interaction
    .addBinding(state.interaction, "bound", { min: 0, max: 1 })
    .on("change", () => {
      updatePostprocessesState();
    });
  interaction
    .addBinding(state.interaction, "factor", {
      min: 0,
      max: 0.05,
      format: (v) => v.toFixed(6),
    })
    .on("change", () => {
      updatePostprocessesState();
    });

  const meshes = pane.addFolder({ title: "Meshes", expanded: false });
  meshes.addBinding(state, "useOneMesh").on("change", () => {
    putMeshesDataToLocalStorage(state);
  });
  meshes
    .addBinding(state, "meshToUse", {
      options: {
        Sphere: "Sphere",
        Cross: "Cross",
        Box: "Box",
        Capsule: "Capsule",
      },
    })
    .on("change", () => {
      putMeshesDataToLocalStorage(state);
    });
  meshes.addBinding(state, "meshRotation").on("change", () => {
    updateMaterials();
  });

  state.meshSettings.forEach((setting) => {
    const f = meshes.addFolder({ title: setting.name, expanded: false });

    f.addBinding(setting.material, "metalness", { min: 0, max: 1 }).on(
      "change",
      () => {
        updateMaterials();
      },
    );
    f.addBinding(setting.material, "roughness", { min: 0, max: 1 }).on(
      "change",
      () => {
        updateMaterials();
      },
    );
    f.addBinding(setting.material, "color").on("change", () => {
      updateMaterials();
    });

    f.addBinding(setting, "scale", { min: 0, max: 10 }).on("change", () => {
      putMeshesDataToLocalStorage(state);
    });
  });

  const cameras = pane.addFolder({ title: "Cameras", expanded: false });
  cameras
    .addBinding(state, "chosenCamera", {
      min: 0,
      max: state.cameras.length - 1,
      step: 1,
    })
    .on("change", () => {
      updateCameraFov();
    });
  state.cameras.forEach((cameraData, index) => {
    const folder = cameras.addFolder({ title: "Camera" + index });
    folder
      .addBinding(cameraData, "cameraPosition", {
        x: { min: -500, max: 500 },
        y: { min: -500, max: 500 },
        z: { min: -500, max: 500 },
      })
      .on("change", () => {
        // updateLights();
      });
    folder
      .addBinding(cameraData, "cameraRotation", {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      })
      .on("change", () => {
        // updateLights();
      });
    folder
      .addBinding(cameraData, "fov", { min: 0, max: 150 })
      .on("change", () => {
        updateCameraFov();
        // updateLights();
      });
  });

  const lights = pane.addFolder({ title: "Lights", expanded: false });

  const rect = lights.addFolder({ title: "Rectangle", expanded: false });

  rect
    .addBinding(state.areaLight, "position", {
      x: { min: -500, max: 500 },
      y: { min: -500, max: 500 },
      z: { min: -500, max: 500 },
    })
    .on("change", () => {
      updateLights();
    });
  rect
    .addBinding(state.areaLight, "rotation", {
      x: { min: -Math.PI, max: Math.PI },
      y: { min: -Math.PI, max: Math.PI },
      z: { min: -Math.PI, max: Math.PI },
    })
    .on("change", () => {
      updateLights();
    });

  rect.addBinding(state.areaLight, "color").on("change", () => {
    updateLights();
  });
  rect
    .addBinding(state.areaLight, "intensity", { min: 0, max: 500 })
    .on("change", () => {
      updateLights();
    });
  rect
    .addBinding(state.areaLight, "width", { min: 0, max: 2500 })
    .on("change", () => {
      updateLights();
    });
  rect
    .addBinding(state.areaLight, "height", { min: 0, max: 2500 })
    .on("change", () => {
      updateLights();
    });
  rect.addBinding(state.areaLight, "height").on("change", () => {
    updateLights();
  });

  const ambient = lights.addFolder({ title: "Ambient", expanded: false });
  ambient.addBinding(state, "ambientLightColor").on("change", () => {
    updateLights();
  });
  ambient
    .addBinding(state, "ambientLightIntensity", { min: 0, max: 300 })
    .on("change", () => {
      updateLights();
    });

  const shadow = pane.addFolder({ title: "Shadow", expanded: false });

  shadow.addBinding(state.shadow, "on").on("change", () => {
    updateLights();
  });
  shadow
    .addBinding(state.shadow, "size", {
      options: {
        256: 256,
        512: 512,
        1024: 1024,
        2048: 2048,
        4096: 4096,
      },
    })
    .on("change", () => {
      updateLights();
    });
  shadow
    .addBinding(state.shadow, "fov", { min: 0, max: 120 })
    .on("change", () => {
      updateLights();
    });
  shadow
    .addBinding(state.shadow, "near", { min: 0, max: 1500 })
    .on("change", () => {
      updateLights();
    });
  shadow
    .addBinding(state.shadow, "far", { min: 0, max: 2000 })
    .on("change", () => {
      updateLights();
    });
  shadow
    .addBinding(state.shadow, "bias", {
      min: 0,
      max: 0.001,

      format: (v) => v.toFixed(6),
    })
    .on("change", () => {
      updateLights();
    });
  shadow
    .addBinding(state.shadow, "radius", { min: 0, max: 50 })
    .on("change", () => {
      updateLights();
    });
  shadow
    .addBinding(state.shadow, "poissonDisks", { min: 0, max: 12, step: 1 })
    .on("change", () => {
      updateLights();
    });

  const sizes = pane.addFolder({ title: "Sizes", expanded: false });
  sizes.addBinding(state, "density", { min: 0, max: 0.99 }).on("change", () => {
    putGridDataToLocalStorage(state);
  });
  sizes
    .addBinding(state, "gridWidth", { min: 0, max: 1000, step: 1 })
    .on("change", () => {
      putGridDataToLocalStorage(state);
    });
  sizes
    .addBinding(state, "gridHeight", { min: 0, max: 1000, step: 1 })
    .on("change", () => {
      putGridDataToLocalStorage(state);
    });
  sizes
    .addBinding(state, "gridDepth", { min: 0, max: 1000, step: 1 })
    .on("change", () => {
      putGridDataToLocalStorage(state);
    });

  pane.addBinding(state, "screenAdaptation").on("change", () => {
    const localStorage = window.localStorage;

    localStorage.setItem(
      "screenAdaptation",
      JSON.stringify({ on: state.screenAdaptation }),
    );
  });

  pane
    .addBinding(state, "stopRenderFPS", { min: 0, max: 144, step: 1 })
    .on("change", () => {
      const localStorage = window.localStorage;

      localStorage.setItem(
        "stopRenderFPS",
        JSON.stringify({ fps: state.stopRenderFPS }),
      );
    });

  pane.addButton({ title: "saveState" }).on("click", () => {
    const a = document.createElement("a");
    const stateToExport = { ...state };
    let file = new Blob([JSON.stringify(stateToExport)], {
      type: "text/plain",
    });
    a.href = URL.createObjectURL(file);
    a.download = "state.json";
    a.click();
  });

  pane.addButton({ title: "loadState" }).on("click", () => {
    input.type = "file";
    input.click();
  });
  pane.addButton({ title: "Import" }).on("click", () => {
    if (input.files && input.files.length > 0) {
      input.files[0].text().then((res) => {
        let newState = JSON.parse(res);
        readState(newState, state);
        updateEverything(state);
        pane.refresh();
      });
    }
  });

  pane.addButton({ title: "clearLocalStorage" }).on("click", () => {
    // localStorage.removeItem("grid");
    localStorage.clear();
  });

  pane.addButton({ title: "Screenshot" }).on("click", () => {
    let a = document.createElement("a");
    let canvas = document.getElementsByTagName("canvas")[0];
    a.href = canvas.toDataURL("image/png");
    a.download = "screenshot.png";
    a.click();
  });
  pane.addButton({ title: "StopRunRender" }).on("click", () => {
    stopRunRender();
  });
  pane.addButton({ title: "ContinueRender" }).on("click", () => {
    continueRender();
  });
}
