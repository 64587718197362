// import * as THREE from "three";
import vertex from "./ZPass.glslv";
import fragment from "./ZPass.glslf";

let ZPass = new THREE.ShaderMaterial({
  vertexShader: vertex,
  fragmentShader: fragment,
  side: THREE.DoubleSide,
  uniforms: {
    u_positionsTexture: { value: null },
    t: { value: 0 },
  },
  defines: {
    ROTATE: true,
  },
});

export default ZPass;
