// import * as THREE from "three";
import vertex from "./iMaterial.glslv";
import fragment from "./iMaterial.glslf";

export const iMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    uniforms: {
      u_positionsTexture: { value: null },
      u_depth: { value: null },
      time: { value: 0 },
    },
  });
};
