import fragment from "./GTAO.glslf";
import vertex from "./Ortho.glslv";

let GTAO = {
  defines: {
    USE_BENT_NORMALS: false,
    SLICE_COUNT: 2,
    DIR_SAMPLES: 4,
  },

  uniforms: {
    u_normal: { value: null },
    u_depth: { value: null },
    resolution: { value: new THREE.Vector2() },
    scaling: { value: 0 },
    viewMatrix: { value: new THREE.Matrix4() },
    projectionMatrixInverse: { value: new THREE.Matrix4() },
    viewMatrixInverse: { value: new THREE.Matrix4() },
    gtaocolor: { value: new THREE.Color() },
  },
  fragmentShader: fragment,
  vertexShader: vertex,
};

export default GTAO;
