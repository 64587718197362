// import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
// import * as THREE from "three";
import shaderLoad from "./shaders";
import getState from "./state";
import ZPass from "./materials/zpass";
import device from "current-device";
// import { LayeredMaterial } from "./layers/LayeredMaterial.mjs";
// import { createStandardLayeredShader } from "./layers/layers";
// import { getAssets } from "./loader";

const shaders = shaderLoad();
let state = getState();

const mobile = device.mobile() || device.tablet();
const FloatType = mobile ? THREE.HalfFloatType : THREE.FloatType;

const shadowCamera = new THREE.PerspectiveCamera(
  state.shadow.fov,
  1,
  state.shadow.near,
  state.shadow.far,
);
// const shadowCamera = new THREE.PerspectiveCamera(60, 1, 10, 550);

const pixelRatio = 1;
const renderWidth = pixelRatio * window.innerWidth;
const renderHeight = pixelRatio * window.innerHeight;

const GTAOScaleDown = 1;
const samples = 0;

const pipeline = [];

const depthNormalColorRenderTarget = new THREE.WebGLMultipleRenderTargets(
  renderWidth,
  renderHeight,
  2,
  {
    samples: samples,
    type: FloatType,
    depthBuffer: true,
    // minFilter: THREE.NearestFilter,
    // magFilter: THREE.NearestFilter,
  },
);

depthNormalColorRenderTarget.depthTexture = new THREE.DepthTexture(
  renderWidth,
  renderHeight,
);
depthNormalColorRenderTarget.depthTexture.format = THREE.DepthFormat;
depthNormalColorRenderTarget.depthTexture.type = THREE.FloatType;
// depthNormalColorRenderTarget.depthTexture.type = THREE.UnsignedIntType;

const zPassRenderTarget = new THREE.WebGLRenderTarget(
  state.shadow.size,
  state.shadow.size,
  {
    depthBuffer: true,
  },
);

zPassRenderTarget.depthTexture = new THREE.DepthTexture(
  state.shadow.size,
  state.shadow.size,
);
zPassRenderTarget.depthTexture.format = THREE.DepthFormat;
zPassRenderTarget.depthTexture.type = THREE.FloatType;

const colorRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth,
  renderHeight,
  { samples: samples, type: FloatType, depthBuffer: true },
);

colorRenderTarget.depthTexture = new THREE.DepthTexture(
  renderWidth,
  renderHeight,
);
colorRenderTarget.depthTexture.format = THREE.DepthFormat;
colorRenderTarget.depthTexture.type = FloatType;

const GTAOMaskRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth * state.GTAODownscaleMultiplier, //4
  renderHeight * state.GTAODownscaleMultiplier, // 4
  { type: FloatType },
);

const GTAOBlurRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth * state.GTAODownscaleMultiplier, //4
  renderHeight * state.GTAODownscaleMultiplier, // 4
  { type: FloatType },
);

const applyMaskRenderTarget = new THREE.WebGLRenderTarget(
  renderWidth,
  renderHeight,
  { type: FloatType },
);

const blurDownsample1RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 1),
  renderHeight / Math.pow(2, 1),
  { type: FloatType },
);

const blurDownsample2RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 2),
  renderHeight / Math.pow(2, 2),
  { type: FloatType },
);
const blurDownsample3RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 3),
  renderHeight / Math.pow(2, 3),
  { type: FloatType },
);
const blurDownsample4RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 4),
  renderHeight / Math.pow(2, 4),
  { type: FloatType },
);
const blurDownsample5RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 5),
  renderHeight / Math.pow(2, 5),
  { type: FloatType },
);

const blurUpsample1RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 4),
  renderHeight / Math.pow(2, 4),
  { type: FloatType },
);
const blurUpsample2RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 3),
  renderHeight / Math.pow(2, 3),
  { type: FloatType },
);
const blurUpsample3RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 2),
  renderHeight / Math.pow(2, 2),
  { type: FloatType },
);
const blurUpsample4RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 1),
  renderHeight / Math.pow(2, 1),
  { type: FloatType },
);
const blurUpsample5RenderTarget = new THREE.WebGLRenderTarget(
  renderWidth / Math.pow(2, 0),
  renderHeight / Math.pow(2, 0),
  { type: FloatType },
);

// const ShaderZPass = new ShaderPass(
//   shaders.ZPass({
//     width: renderWidth,
//     height: renderHeight,
//   }),
// );

const blurDownsample1ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth,
    height: renderHeight,
  }),
);
const blurDownsample2ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 2,
    height: renderHeight / 2,
  }),
);
const blurDownsample3ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 4,
    height: renderHeight / 4,
  }),
);
const blurDownsample4ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 8,
    height: renderHeight / 8,
  }),
);
const blurDownsample5ShaderPass = new ShaderPass(
  shaders.BloomDownsample({
    width: renderWidth / 16,
    height: renderHeight / 16,
  }),
);

const blurUpsample1ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample2ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample3ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample4ShaderPass = new ShaderPass(shaders.BloomUpsample(false));
const blurUpsample5ShaderPass = new ShaderPass(shaders.BloomUpsample(true));

const GTAOShaderPass = new ShaderPass(shaders.GTAO);
const GTAOBlurShaderPass = new ShaderPass(shaders.SSAOBlur);
const applyMaskPass = new ShaderPass(shaders.DeferedLight);
const FinalPass = new ShaderPass(shaders.Final);
// FinalPass.material = new LayeredMaterial(
//   shaders.Final,
//   createStandardLayeredShader(shaders.Final.fragmentShader),
//   state,
// );

// function getActiveLayersFromState() {
//   return state.layers.filter((layer) => layer.active);
// }
//
// function updateLayersData(material) {
//   const assets = getAssets();
//   const activeLayers = getActiveLayersFromState();
//   material.cleanLayers();
//   activeLayers.forEach((activeLayer) => {
//     material.addLayer(activeLayer, assets.layerTextures);
//   });
// }

function injectState(inState) {
  state = inState;
}

function updateShadowMaterial() {
  ZPass.defines["ROTATE"] = state.meshRotation;
  ZPass.needsUpdate = true;
}

function perFrameShadowMaterial(t) {
  ZPass.uniforms.t.value = t;
}

function updateShadowCamera(areaLightObject) {
  const width = areaLightObject.scale.x;
  const height = areaLightObject.scale.y;
  areaLightObject.updateMatrix();
  areaLightObject.updateMatrixWorld();

  const direction = new THREE.Vector3(0, 0, -1);
  const areaQuaternion = areaLightObject.quaternion.clone();
  areaQuaternion.normalize();
  direction.applyQuaternion(areaLightObject.quaternion);

  direction.normalize();
  direction.multiplyScalar(
    height / (2 * Math.tan((shadowCamera.fov / 2 / 180) * Math.PI)),
  );

  shadowCamera.position.copy(areaLightObject.position);
  shadowCamera.position.add(direction);
  shadowCamera.lookAt(areaLightObject.position);

  shadowCamera.fov = state.shadow.fov;
  shadowCamera.near = state.shadow.near;
  shadowCamera.far = state.shadow.far;

  shadowCamera.updateMatrix();
  shadowCamera.updateProjectionMatrix();
  // shadowCamera.updateMatrixWorld(true);
}

function setRenderTargetsSizes() {
  const pixelRatio = state.pixelRatio;
  const renderWidth = state.width * pixelRatio; //pixelRatio * window.innerWidth;
  const renderHeight = state.height * pixelRatio; //pixelRatio * window.innerHeight;

  const samples = state.geometryRenderMSAASamples;

  depthNormalColorRenderTarget.setSize(renderWidth, renderHeight);
  depthNormalColorRenderTarget.samples = samples;

  // colorRenderTarget.setSize(renderWidth, renderHeight);
  // colorRenderTarget.samples = samples;

  if (state.shadow.on && zPassRenderTarget.width != state.shadow.size) {
    zPassRenderTarget.setSize(state.shadow.size, state.shadow.size);
    zPassRenderTarget.depthTexture = new THREE.DepthTexture(
      state.shadow.size,
      state.shadow.size,
    );
    zPassRenderTarget.depthTexture.format = THREE.DepthFormat;
    zPassRenderTarget.depthTexture.type = FloatType;
  }

  GTAOMaskRenderTarget.setSize(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );
  GTAOBlurRenderTarget.setSize(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );

  applyMaskRenderTarget.setSize(renderWidth, renderHeight);
  blurDownsample1RenderTarget.setSize(renderWidth / 2, renderHeight / 2);
  blurDownsample2RenderTarget.setSize(renderWidth / 4, renderHeight / 4);
  blurDownsample3RenderTarget.setSize(renderWidth / 8, renderHeight / 8);
  blurDownsample4RenderTarget.setSize(renderWidth / 16, renderHeight / 16);
  blurDownsample5RenderTarget.setSize(renderWidth / 32, renderHeight / 32);

  blurUpsample1RenderTarget.setSize(renderWidth / 16, renderHeight / 16);
  blurUpsample2RenderTarget.setSize(renderWidth / 8, renderHeight / 8);
  blurUpsample3RenderTarget.setSize(renderWidth / 4, renderHeight / 4);
  blurUpsample4RenderTarget.setSize(renderWidth / 2, renderHeight / 2);
  blurUpsample5RenderTarget.setSize(renderWidth, renderHeight);

  GTAOShaderPass.material.uniforms.resolution.value.set(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );

  GTAOBlurShaderPass.material.uniforms.resolution.value.set(
    renderWidth * state.GTAODownscaleMultiplier,
    renderHeight * state.GTAODownscaleMultiplier,
  );
  FinalPass.material.uniforms.resolution.value.set(renderWidth, renderHeight);
}

function setUniforms(camera, lights, tex) {
  GTAOShaderPass.material.uniforms.viewMatrix.value = camera.matrixWorldInverse;
  GTAOShaderPass.material.uniforms.viewMatrixInverse.value = camera.matrixWorld;
  GTAOShaderPass.material.uniforms.projectionMatrixInverse.value =
    camera.projectionMatrixInverse;

  const rect = lights.rect;
  const ambient = lights.ambient;
  ZPass.uniforms.u_positionsTexture.value = tex;

  applyMaskPass.material.uniforms.viewMatrixInverse.value = camera.matrixWorld;
  applyMaskPass.material.uniforms.projectionMatrixInverse.value =
    camera.projectionMatrixInverse;
  applyMaskPass.material.uniforms.viewMatrix.value = camera.matrixWorldInverse;

  applyMaskPass.material.uniforms.positionCamera.value = camera.position;

  applyMaskPass.uniforms.lights.value = [];
  applyMaskPass.uniforms.lights.value.push(rect);
  applyMaskPass.uniforms.ambientColor.value = new THREE.Color(ambient.color);
  applyMaskPass.uniforms.ambientIntensity.value = ambient.intensity;
  applyMaskPass.uniforms.interactionColor.value = new THREE.Color(
    state.interaction.color,
  );

  shadowCamera.updateMatrix();
  shadowCamera.updateProjectionMatrix();
  shadowCamera.updateMatrixWorld(true);
  applyMaskPass.uniforms.shadowProjectionMatrix.value.copy(
    shadowCamera.projectionMatrix,
  );
  applyMaskPass.uniforms.shadowViewMatrix.value.copy(
    shadowCamera.matrixWorldInverse,
  );

  updatePostprocessesState();
}

function composerRender(renderer, scene, camera) {
  for (let i = 0; i < pipeline.length; i++) {
    pipeline[i](renderer, scene, camera);
  }
}

function buildPipeline() {
  while (pipeline.length > 0) pipeline.pop();

  if (state.shadow.on) {
    pipeline.push((renderer, scene, camera) => {
      renderer.setRenderTarget(zPassRenderTarget);
      scene.overrideMaterial = ZPass;
      // renderer.resetState();
      renderer.render(scene, shadowCamera);
    });
  }

  pipeline.push((renderer, scene, camera) => {
    renderer.setRenderTarget(depthNormalColorRenderTarget);
    scene.overrideMaterial = null;
    // renderer.resetState();
    renderer.render(scene, camera);
  });

  if (state.GTAO === "non-blured")
    pipeline.push((renderer, scene, camera) => {
      GTAOShaderPass.render(renderer, GTAOMaskRenderTarget);
      applyMaskPass.render(renderer, applyMaskRenderTarget);
    });

  if (state.GTAO === "blured")
    pipeline.push((renderer, scene, camera) => {
      GTAOShaderPass.render(renderer, GTAOMaskRenderTarget);
      GTAOBlurShaderPass.render(renderer, GTAOBlurRenderTarget);
      applyMaskPass.render(renderer, applyMaskRenderTarget);
    });

  if (state.Bloom === "on")
    pipeline.push((renderer, scene, camera) => {
      blurDownsample1ShaderPass.render(renderer, blurDownsample1RenderTarget);
      blurDownsample2ShaderPass.render(renderer, blurDownsample2RenderTarget);
      blurDownsample3ShaderPass.render(renderer, blurDownsample3RenderTarget);
      blurDownsample4ShaderPass.render(renderer, blurDownsample4RenderTarget);
      blurDownsample5ShaderPass.render(renderer, blurDownsample5RenderTarget);

      blurUpsample1ShaderPass.render(renderer, blurUpsample1RenderTarget);
      blurUpsample2ShaderPass.render(renderer, blurUpsample2RenderTarget);
      blurUpsample3ShaderPass.render(renderer, blurUpsample3RenderTarget);
      blurUpsample4ShaderPass.render(renderer, blurUpsample4RenderTarget);
      blurUpsample5ShaderPass.render(renderer, blurUpsample5RenderTarget);
    });

  pipeline.push((renderer, scene, camera) => {
    // renderer.setRenderTarget(null);
    // renderer.clear();

    renderer.resetState();
    FinalPass.render(renderer, null);
  });

  GTAOShaderPass.material.uniforms.u_normal.value =
    depthNormalColorRenderTarget.texture[1];
  GTAOShaderPass.material.uniforms.u_depth.value =
    depthNormalColorRenderTarget.depthTexture;

  GTAOBlurShaderPass.material.uniforms.u_color.value =
    GTAOMaskRenderTarget.texture;

  applyMaskPass.material.uniforms.u_albedo_roughness.value =
    depthNormalColorRenderTarget.texture[0];

  applyMaskPass.material.uniforms.u_normal_metalness.value =
    depthNormalColorRenderTarget.texture[1];
  applyMaskPass.material.uniforms.u_depth.value =
    depthNormalColorRenderTarget.depthTexture;
  applyMaskPass.material.uniforms.u_ssao_mask.value =
    state.GTAO === "blured"
      ? GTAOBlurRenderTarget.texture
      : GTAOMaskRenderTarget.texture;

  applyMaskPass.material.uniforms.u_shadow_depth.value =
    zPassRenderTarget.depthTexture;

  blurDownsample1ShaderPass.material.uniforms.u_previous.value =
    state.GTAO !== "off"
      ? applyMaskRenderTarget.texture
      : colorRenderTarget.texture;
  //depthNormalColorRenderTarget.texture[0]; //applyMaskRenderTarget.texture;
  blurDownsample2ShaderPass.material.uniforms.u_previous.value =
    blurDownsample1RenderTarget.texture;
  blurDownsample3ShaderPass.material.uniforms.u_previous.value =
    blurDownsample2RenderTarget.texture;
  blurDownsample4ShaderPass.material.uniforms.u_previous.value =
    blurDownsample3RenderTarget.texture;
  blurDownsample5ShaderPass.material.uniforms.u_previous.value =
    blurDownsample4RenderTarget.texture;

  blurUpsample1ShaderPass.material.uniforms.u_previous.value =
    blurDownsample5RenderTarget.texture;
  blurUpsample1ShaderPass.material.uniforms.u_background.value =
    blurDownsample4RenderTarget.texture;

  blurUpsample2ShaderPass.material.uniforms.u_previous.value =
    blurUpsample1RenderTarget.texture;
  blurUpsample2ShaderPass.material.uniforms.u_background.value =
    blurDownsample3RenderTarget.texture;

  blurUpsample3ShaderPass.material.uniforms.u_previous.value =
    blurUpsample2RenderTarget.texture;
  blurUpsample3ShaderPass.material.uniforms.u_background.value =
    blurDownsample2RenderTarget.texture;

  blurUpsample4ShaderPass.material.uniforms.u_previous.value =
    blurUpsample3RenderTarget.texture;
  blurUpsample4ShaderPass.material.uniforms.u_background.value =
    blurDownsample1RenderTarget.texture;

  blurUpsample5ShaderPass.material.uniforms.u_previous.value =
    blurUpsample4RenderTarget.texture;
  blurUpsample5ShaderPass.material.uniforms.u_background.value =
    state.GTAO !== "off"
      ? applyMaskRenderTarget.texture
      : colorRenderTarget.texture;

  //depthNormalColorRenderTarget.texture[0]; //applyMaskRenderTarget.texture;

  if (state.Bloom === "off" && state.GTAO === "off")
    FinalPass.material.uniforms.u_color.value = colorRenderTarget.texture;
  else if (state.Bloom === "on")
    FinalPass.material.uniforms.u_color.value =
      blurUpsample5RenderTarget.texture;
  else
    FinalPass.material.uniforms.u_color.value = applyMaskRenderTarget.texture;

  FinalPass.renderToScreen = true;
}

function updatePostprocessesState() {
  //GTAOShaderPass.material.uniforms.resolution.value.set(
  //  renderWidth / GTAOScaleDown,
  //  renderHeight / GTAOScaleDown
  //);

  GTAOShaderPass.material.defines["SLICE_COUNT"] = state.sliceCount;
  GTAOShaderPass.material.defines["DIR_SAMPLES"] = state.directionalSamples;

  GTAOShaderPass.material.uniforms.scaling.value = state.scaling;

  // applyMaskPass.material.uniforms.gtaocolor.value.set(state.GTAOcolor);

  applyMaskPass.uniforms.background.value = new THREE.Color(
    state.backgroundColor,
  );

  applyMaskPass.uniforms.v_bound.value = state.interaction.bound;
  applyMaskPass.uniforms.v_factor.value = state.interaction.factor;

  applyMaskPass.uniforms.shadowRadius.value = state.shadow.radius;
  applyMaskPass.uniforms.shadowBias.value = state.shadow.bias;
  applyMaskPass.uniforms.numOfPoissonDisks.value = state.shadow.poissonDisks;
  applyMaskPass.uniforms.shadowResolution.value = state.shadow.size;
  applyMaskPass.material.defines["SHADOW"] = state.shadow.on;
  applyMaskPass.material.needsUpdate = true;

  blurUpsample1ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample1ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample2ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample2ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample3ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample3ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample4ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample4ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  blurUpsample5ShaderPass.material.uniforms.filterRadius.value =
    state.filterRadius;
  blurUpsample5ShaderPass.material.uniforms.bloomStrength.value =
    state.bloomStrength;

  FinalPass.material.defines["TONEMAP"] = state.ToneMapping !== "NONE";
  FinalPass.material.defines["TONEMAP_SIMPLE"] =
    state.ToneMapping === "TONEMAP_SIMPLE";
  FinalPass.material.defines["TONEMAP_REINHARD"] =
    state.ToneMapping === "TONEMAP_REINHARD";
  FinalPass.material.defines["TONEMAP_REINHARD_LUMA"] =
    state.ToneMapping === "TONEMAP_REINHARD_LUMA";
  FinalPass.material.defines["TONEMAP_REINHARD_WHITE"] =
    state.ToneMapping === "TONEMAP_REINHARD_WHITE";
  FinalPass.material.defines["TONEMAP_FILMIC"] =
    state.ToneMapping === "TONEMAP_FILMIC";
  FinalPass.material.defines["TONEMAP_PHOTOGRAPHIC"] =
    state.ToneMapping === "TONEMAP_PHOTOGRAPHIC";
  FinalPass.material.defines["TONEMAP_UNCHARTED"] =
    state.ToneMapping === "TONEMAP_UNCHARTED";

  FinalPass.material.uniforms.toneMappingExposure.value =
    state.ToneMappingExposure;

  // updateLayersData(FinalPass.material);
  // FinalPass.material.compile();

  FinalPass.material.depthTest = false;
  FinalPass.material.depthWrite = false;
  FinalPass.material.needsUpdate = true;
  GTAOShaderPass.material.needsUpdate = true;

  setRenderTargetsSizes();
  buildPipeline();
}

function cleanPipeline() {
  while (pipeline.length > 0) {
    pipeline.pop();
  }
}

function getZPassTexture() {
  return zPassRenderTarget.depthTexture;
}

export {
  injectState,
  perFrameShadowMaterial,
  updateShadowMaterial,
  updateShadowCamera,
  cleanPipeline,
  composerRender,
  setUniforms,
  updatePostprocessesState,
  setRenderTargetsSizes,
  getZPassTexture,
};
