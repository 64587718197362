// import * as THREE from "three";
// import vertex from "./physicalMaterial.glslv";
// import fragment from "./physicalMaterial.glslf";
import vertexUniforms from "./elements/vertexUniforms.glslv";
import vertexPosition from "./elements/vertexPosition.glslv";
import fragmentTarget from "./elements/fragmentTarget.glslf";
import fragmentVarying from "./elements/fragmentVarying.glslf";
import fragmentRender from "./elements/fragmentRender.glslf";
import fragmentDiffuse from "./elements/fragmentDiffuse.glslf";

const { Vector3, Color, ShaderChunk, ShaderLib, UniformsUtils } = THREE;

const meshphys_frag_before_main = ShaderChunk["meshphysical_frag"].slice(
  0,
  ShaderChunk["meshphysical_frag"].indexOf(
    "outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + clearcoatSpecular * material.clearcoat",
  ),
);

function insertBetween(shader, insertQueries, inserts) {
  let rShader = shader;
  insertQueries.forEach((iq, index) => {
    let splits = rShader.split(iq);
    rShader = splits.join(iq + inserts[index]);
  });

  return rShader;
}

const vertexQueryBeforeMain = "#include <common>\n";
const vertexQueryMain = "#include <begin_vertex>\n";

const vertexBeforeMain = vertexUniforms;
const vertexMain = vertexPosition;

// const vertexBeforeMain = "varying vec3 wPosition;\n";
// const vertexMain = "\twPosition = vec3(modelMatrix * vec4(position, 1.0));\n";
const vertexShader = insertBetween(
  ShaderChunk["meshphysical_vert"],
  [vertexQueryBeforeMain, vertexQueryMain],
  [vertexBeforeMain, vertexMain],
);

// const fragmentShader = ShaderChunk["meshphysical_frag"];
const fragmentQueryTarget = "#define STANDARD\n";
const fragmentQueryVarying = "varying vec3 vViewPosition;\n";
const fragmentQueryRender = "#include <output_fragment>\n";
const fragmentQueryDiffuse = "vec4 diffuseColor = vec4( diffuse, opacity );\n";

const fragmentShader = insertBetween(
  ShaderChunk["meshphysical_frag"],
  [
    fragmentQueryTarget,
    fragmentQueryVarying,
    fragmentQueryDiffuse,
    fragmentQueryRender,
  ],
  [fragmentTarget, fragmentVarying, fragmentDiffuse, fragmentRender],
);

// console.log(ShaderChunk["meshphysical_frag"], fragmentShader);

const physicalMaterial = (options) => {
  const m = new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    uniforms: UniformsUtils.merge([
      ShaderLib.physical.uniforms,
      {
        resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        u_positionsTexture: { value: null },
        u_depth: { value: null },
      },
    ]),
    defines: {
      STANDARD: "",
      PHYSICAL: "",
      COMPOSER: false,
      USE_MAP: options && options.map ? true : false,
      USE_UV: options && options.map ? true : false,
      MAP_UV: options && options.map ? "uv" : false,
    },
    lights: true,
  });

  if (options && options.map) m.uniforms.map.value = options.map;
  if (options && options.color) m.uniforms.diffuse.value = options.color;
  //m.isMeshPhysicalMaterial = true;

  return m;
};

export default physicalMaterial;
