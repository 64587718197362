import fragment from "./GBuffer.glslf";
import vertex from "./GBuffer.glslv";
// import { GLSL3 } from "three";
// import * as THREE from "three";

let GBufferMaterial = () => {
  return new THREE.ShaderMaterial({
    uniforms: {
      color: { type: "v3", value: null },
      roughness: { type: "f", value: null },
      metalness: { type: "f", value: null },
      emissiveColor: { type: "v3", value: null },
      emissiveStrength: { type: "f", value: null },
      u_positionsTexture: { value: null },
      t: { value: 0 },
    },
    defines: {
      PARTICLES: false,
      ROTATE: true,
    },
    fragmentShader: fragment,
    vertexShader: vertex,
  });
};

export default GBufferMaterial;
