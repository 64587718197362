import fragment from "./deferedLight.glslf";
import vertex from "./Ortho.glslv";
// import { Matrix4 } from "three";
// import * as THREE from "three";

const {
  NearestFilter,
  FloatType,
  LinearFilter,
  DataTexture,
  RGBAFormat,
  UVMapping,
  ClampToEdgeWrapping,
  LinearSRGBColorSpace,
  Matrix4,
} = THREE;
import { g_ltc_1, g_ltc_2 } from "../ltc";

let ltc = null;
let ltc2 = null;

// ["ltc_mat"].forEach(url => {
//   (new TextureLoader()).load(url + ".png", (texture) => {
//     ltc = texture;
//     ltc.minFilter = NearestFilter;
//     ltc.type = FloatType;
//   })
// })
function generateLTC() {
  const width = 64;
  const height = 64;

  const size = width * height;
  //const data = new Uint8Array(4 * size);//Float32Array( 4 * size );
  const data = new Float32Array(g_ltc_1);
  const data2 = new Float32Array(g_ltc_2);

  // for ( let i = size - 1; i >= 0; i -- ) {

  //   const stride = i * 4;

  //   data[ stride ] = (g_ltc_1[stride ] + 1) * 128;
  //   data[ stride + 1 ] = (g_ltc_1[stride + 1] + 1) * 128;
  //   data[ stride + 2 ] = (g_ltc_1[stride + 2] + 1) * 128;
  //   data[ stride + 3 ] = (g_ltc_1[stride + 3] + 1) * 128;

  // }

  // for ( let i = 0; i < size; i ++ ) {

  //   const stride = (size - i - 1) * 4;

  //   data[ stride ] = g_ltc_1[stride ];
  //   data[ stride + 1 ] = g_ltc_1[stride + 1];
  //   data[ stride + 2 ] = g_ltc_1[stride + 2];
  //   data[ stride + 3 ] = g_ltc_1[stride + 3];

  // }

  // used the buffer to create a DataTexture
  //DataTexture( data, width, height, format, type, mapping, wrapS, wrapT, magFilter, minFilter, anisotropy, encoding )

  ltc = new DataTexture(
    data,
    width,
    height,
    RGBAFormat,
    FloatType,
    UVMapping,
    ClampToEdgeWrapping,
    ClampToEdgeWrapping,
    LinearFilter,
    NearestFilter,
    1,
    LinearSRGBColorSpace,
  );

  ltc.internalFormat = "RGBA32F";

  //ltc.flipY = false;

  ltc.needsUpdate = true;

  ltc2 = new DataTexture(
    data2,
    width,
    height,
    RGBAFormat,
    FloatType,
    UVMapping,
    ClampToEdgeWrapping,
    ClampToEdgeWrapping,
    LinearFilter,
    NearestFilter,
    1,
    LinearSRGBColorSpace,
  );

  ltc2.internalFormat = "RGBA32F";

  //ltc.flipY = false;

  ltc2.needsUpdate = true;
}

generateLTC();

let DeferedLight = new THREE.ShaderMaterial({
  vertexShader: vertex,
  fragmentShader: fragment,
  uniforms: {
    // environmentMap: { type: "t", value: null },
    u_depth: { type: "t", value: null },
    u_normal_metalness: { type: "t", value: null },
    u_albedo_roughness: { type: "t", value: null },
    // u_emission: { type: "t", value: null },
    u_ssao_mask: { type: "t", value: null },
    u_shadow_depth: { value: null },

    ltc_mag: { type: "t", value: ltc2 },
    ltc_mat: { type: "t", value: ltc },

    projectionMatrixInverse: { type: "m4", value: new Matrix4() },
    viewMatrixInverse: { type: "m4", value: new Matrix4() },
    viewMatrix: { type: "m4", value: new Matrix4() },

    ambientColor: { type: "v3", value: null },
    ambientIntensity: { type: "f", value: null },
    interactionColor: { type: "v3", value: null },

    fogColor: { type: "v3", value: new THREE.Color("#000000") },
    fogDensity: { type: "f", value: 0 },
    background: { value: new THREE.Color("white") },

    positionCamera: { type: "v3", value: null },
    lights: { value: [] },
    v_bound: { value: 0 },
    v_factor: { value: 0 },

    // envMapIntensity: { type: "f", value: 0 },
    shadowDarkness: { type: "f", value: 1.0 },

    shadowProjectionMatrix: { value: new THREE.Matrix4() },
    shadowViewMatrix: { value: new THREE.Matrix4() },

    shadowRadius: { type: "f", value: 3 },
    shadowBias: { type: "f", value: 0.00005 }, //0.0005 },
    numOfPoissonDisks: { type: "f", value: 4 },
    shadowResolution: { value: 512 },
    //
    // cameraXAngle: { type: "f", value: null },
    // cameraYAngle: { type: "f", value: null },
    // cameraZAngle: { type: "f", value: null },
    //
    // environmentXAngle: { type: "f", value: null },
    // environmentYAngle: { type: "f", value: null },
    // environmentZAngle: { type: "f", value: null },
    //
    // hueAdjust: { type: "f", value: 0 },
    //
    // brightness: { type: "f", value: null },
    // contrast: { type: "f", value: null },
    // saturation: { type: "f", value: null },
    //
    // maxRadiation: { type: "f", value: null },
  },
  defines: {
    SHADOW: true,
    NUM_LIGHTS: 1,
    USE_SSAO: true,
    GTAOMULTIBOUNCE: true,
  },
});

export default DeferedLight;
