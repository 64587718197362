// import * as THREE from "three";

const state = {
  width: window.innerWidth,
  height: window.innerHeight,
  zMultiplier: 0.3, //1.0, //0.3,

  defaultFrameBufferAntialiasing: true, // true,
  geometryRenderMSAASamples: 0,
  pixelRatio: 1,
  GTAO: "non-blured",
  GTAODownscaleMultiplier: 1,
  GTAOcolor: "#000000", //"#0000ff",

  applyGTAORegime: "multiply", //"add", "mix"
  applyGTAOPower: 1.8,

  gtaoMultibounce: true,
  scaling: 8, //6, //4.456521739130435,
  sliceCount: 6,
  directionalSamples: 5,
  Bloom: "off", //"on",
  filterRadius: 0.001,
  bloomStrength: 0.013,
  ToneMappingExposure: 2,
  ToneMapping: "TONEMAP_UNCHARTED",

  fluidity: 0.92,
  speed: 1.0,
  wavesStrength: 1.0,
  vortexBorder: 2.5,
  vortexStrength: 1.0,

  regime: "still", //"vortex", // "waves", "still",

  chosenCamera: 0,
  cameras: [
    {
      cameraPosition: { x: 83.0, y: 20.23, z: 203.83 },
      cameraRotation: { x: 0.14, y: 0.0, z: 0.0 },
      // cameraRotation: new THREE.Euler(0.14, 0.0, 0.0),
      fov: 34,
    },
    {
      cameraPosition: { x: 79.0, y: 126.23, z: 58.83 },
      cameraRotation: { x: -1.4, y: 0.0, z: 0.0 },
      fov: 25,
    },
    {
      cameraPosition: { x: 199.0, y: 106.23, z: 208.83 },
      cameraRotation: { x: -0.4, y: 0.5, z: 0.0 },
      fov: 34.24,
    },
  ],

  shadow: {
    on: false,
    size: 512,
    fov: 30,
    near: 500,
    far: 800,
    bias: 0.0005, //0.00005
    radius: 2.17,
    poissonDisks: 4,
  },

  interaction: {
    color: "#ffffff",
    strength: 8,
    radius: 10,
    bound: 1.0,
    factor: 0.01,
  },

  areaLight: {
    position: { x: 70, y: 170, z: 0 },
    rotation: { x: Math.PI / 2, y: 0, z: 0 },
    color: "#4ef7ff", //"#1363df", //"#ffffff",
    intensity: 16,
    width: 81, //100,
    height: 300, //100,
    rectVisible: false,
    // lookAtCenter: false,
  },

  backgroundColor: "#ffffff",
  ambientLightColor: "#1363df",
  ambientLightIntensity: 15,

  screenAdaptation: true,
  density: 0.1,
  gridWidth: 165,
  gridHeight: 130,
  gridDepth: 75,
  // density: 0.1,
  useOneMesh: false,
  meshToUse: "Sphere",
  meshRotation: true,
  cameraToLook: 1,
  meshSettings: [
    {
      name: "Sphere",
      material: {
        metalness: 0.1,
        roughness: 0.2,
        color: "#1363df",
      },

      scale: 1.0,
    },
    {
      name: "Cross",
      material: {
        metalness: 0.1,
        roughness: 0.7,
        color: "#86b6fe",
      },
      scale: 0.3,
    },
    {
      name: "Box",
      material: {
        metalness: 0.1,
        roughness: 0.4,
        color: "#1db9fc",
      },
      scale: 1.0,
    },
    {
      name: "Capsule",
      material: {
        metalness: 0.1,
        roughness: 0.2,
        color: "#1363df",
      },
      scale: 1.0,
    },
  ],
  stopRenderFPS: 20,
};
export default () => {
  return state;
};
